import React, { useEffect, useState } from "react";
import WebRadioPlayer from "./WebRadioPlayer";
import "./App.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Preloader from './Preloader';

function App() {
    const [selectedStation, setSelectedStation] = useState(null); // pas de flux par défaut
    const [playingStation, setPlayingStation] = useState(null); // Ajoute ce state pour gérer la station en lecture
    const [heroBackground, setHeroBackground] = useState("");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    const [loading, setLoading] = useState(true);
    // const [currentImage, setCurrentImage] = useState(0);

    // Noms des radios et leurs logos et liens correspondants
    const radios = [
        { name: "Starz 60s", logo: `${process.env.PUBLIC_URL}/logos/60s.png`, stream: "https://audio.starzwebradio.com/stream/13/", historyUrl: "http://62.210.204.2:8000/played.html?sid=13" },
        { name: "Starz 70s", logo: `${process.env.PUBLIC_URL}/logos/70s.png`, stream: "https://audio.starzwebradio.com/stream/15/", historyUrl: "http://62.210.204.2:8000/played.html?sid=15" },
        { name: "Starz 80s", logo: `${process.env.PUBLIC_URL}/logos/80s.png`, stream: "https://audio.starzwebradio.com/stream/2/", historyUrl: "http://62.210.204.2:8000/played.html?sid=2" },
        { name: "Starz 90s", logo: `${process.env.PUBLIC_URL}/logos/90s.png`, stream: "https://audio.starzwebradio.com/stream/9/", historyUrl: "http://62.210.204.2:8000/played.html?sid=9" },
        { name: "Starz Blues", logo: `${process.env.PUBLIC_URL}/logos/blues.png`, stream: "https://audio.starzwebradio.com/stream/4/", historyUrl: "http://62.210.204.2:8000/played.html?sid=4" },
        { name: "Starz Christmas", logo: `${process.env.PUBLIC_URL}/logos/christmas.png`, stream: "https://audio.starzwebradio.com/stream/12/", historyUrl: "http://62.210.204.2:8000/played.html?sid=12" },
        { name: "Starz Classical", logo: `${process.env.PUBLIC_URL}/logos/classical.png`, stream: "https://audio.starzwebradio.com/stream/17/", historyUrl: "http://62.210.204.2:8000/played.html?sid=17" },
        { name: "Starz Country", logo: `${process.env.PUBLIC_URL}/logos/country.png`, stream: "https://audio.starzwebradio.com/stream/16/", historyUrl: "http://62.210.204.2:8000/played.html?sid=16" },
        { name: "Starz Dance", logo: `${process.env.PUBLIC_URL}/logos/dance.png`, stream: "https://audio.starzwebradio.com/stream/7/", historyUrl: "http://62.210.204.2:8000/played.html?sid=7" },
        { name: "Starz Funk", logo: `${process.env.PUBLIC_URL}/logos/funk.png`, stream: "https://audio.starzwebradio.com/stream/11/", historyUrl: "http://62.210.204.2:8000/played.html?sid=11" },
        { name: "Starz Italia", logo: `${process.env.PUBLIC_URL}/logos/italia.png`, stream: "https://audio.starzwebradio.com/stream/19/", historyUrl: "http://62.210.204.2:8000/played.html?sid=19" },
        { name: "Starz Jazz", logo: `${process.env.PUBLIC_URL}/logos/jazz.png`, stream: "https://audio.starzwebradio.com/stream/5/", historyUrl: "http://62.210.204.2:8000/played.html?sid=5" },
        { name: "Starz K-pop", logo: `${process.env.PUBLIC_URL}/logos/kpop.png`, stream: "https://audio.starzwebradio.com/stream/18/", historyUrl: "http://62.210.204.2:8000/played.html?sid=18" },
        { name: "Starz Latino", logo: `${process.env.PUBLIC_URL}/logos/latino.png`, stream: "https://audio.starzwebradio.com/stream/20/", historyUrl: "http://62.210.204.2:8000/played.html?sid=20" },
        { name: "Starz Lounge", logo: `${process.env.PUBLIC_URL}/logos/lounge.png`, stream: "https://audio.starzwebradio.com/stream/3/", historyUrl: "http://62.210.204.2:8000/played.html?sid=3" },
        { name: "Starz Love", logo: `${process.env.PUBLIC_URL}/logos/love.png`, stream: "https://audio.starzwebradio.com/stream/14/", historyUrl: "http://62.210.204.2:8000/played.html?sid=14" },
        { name: "Starz New Hits", logo: `${process.env.PUBLIC_URL}/logos/newhits.png`, stream: "https://audio.starzwebradio.com/stream/6/", historyUrl: "http://62.210.204.2:8000/played.html?sid=6" },
        { name: "Starz Rap", logo: `${process.env.PUBLIC_URL}/logos/rap.png`, stream: "https://audio.starzwebradio.com/stream/10/", historyUrl: "http://62.210.204.2:8000/played.html?sid=10" },
        { name: "Starz RnB", logo: `${process.env.PUBLIC_URL}/logos/rnb.png`, stream: "https://audio.starzwebradio.com/stream/8/", historyUrl: "http://62.210.204.2:8000/played.html?sid=8" },
    ];

    // Tableau des images spécifiques pour la section hero
    const heroImages = [
        // Photos normales
        // `${process.env.PUBLIC_URL}/black.png`,
        // `${process.env.PUBLIC_URL}/grise.png`,
        // `${process.env.PUBLIC_URL}/blonde.png`,
        // `${process.env.PUBLIC_URL}/noir.png`,
        // `${process.env.PUBLIC_URL}/chinois.png`,
        // `${process.env.PUBLIC_URL}/brun.png`,
        // `${process.env.PUBLIC_URL}/gris.png`,
        // `${process.env.PUBLIC_URL}/chinoise.png`,
        // `${process.env.PUBLIC_URL}/brune.png`,
        // `${process.env.PUBLIC_URL}/blondebaskets.png`,
        // `${process.env.PUBLIC_URL}/brunepiedsnus.png`,
        // `${process.env.PUBLIC_URL}/roussechaussettes2.png`,
        // `${process.env.PUBLIC_URL}/roussebaskets.png`,
        // `${process.env.PUBLIC_URL}/brunebaskets.png`,
        // `${process.env.PUBLIC_URL}/brunechaussettes.png`,
        // `${process.env.PUBLIC_URL}/blondechaussettes.png`,
        // `${process.env.PUBLIC_URL}/noirechaussttes.png`,
        // `${process.env.PUBLIC_URL}/asiatchaussettes.png`,
        // `${process.env.PUBLIC_URL}/roussechaussettes.png`,
        // `${process.env.PUBLIC_URL}/groupe.png`,
        // `${process.env.PUBLIC_URL}/groupemixte.png`,
        // `${process.env.PUBLIC_URL}/groupechaussettes2.png`,
        // `${process.env.PUBLIC_URL}/groupechaussettes.png`,
        // `${process.env.PUBLIC_URL}/groupechaussettes3.png`,

        // Photos halloween
        // `${process.env.PUBLIC_URL}/halloween/groupemixte.png`,
        // `${process.env.PUBLIC_URL}/halloween/groupemixte2.png`,
        // `${process.env.PUBLIC_URL}/halloween/groupemixte3.png`,
        // `${process.env.PUBLIC_URL}/halloween/groupemixte4.png`,
        // `${process.env.PUBLIC_URL}/halloween/solo.png`,
        // `${process.env.PUBLIC_URL}/halloween/solo3.png`,
        // `${process.env.PUBLIC_URL}/halloween/hhsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/hhsw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/jasonsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/jasonsw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/jasonsw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/freddysw.png`,
        // `${process.env.PUBLIC_URL}/halloween/freddysw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/freddysw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/chuckysw.png`,
        // `${process.env.PUBLIC_URL}/halloween/chuckysw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/chuckysw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/sawsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/sawsw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/sawsw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/pinheadsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/pinheadsw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/pinheadsw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/zombiesw.png`,
        // `${process.env.PUBLIC_URL}/halloween/zombiesw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/zombiesw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/leprechaunsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/momiesw.png`,
        // `${process.env.PUBLIC_URL}/halloween/draculasw.png`,
        // `${process.env.PUBLIC_URL}/halloween/creaturesw.png`,
        // `${process.env.PUBLIC_URL}/halloween/robotsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/robotsw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/leatherfacesw.png`,
        // `${process.env.PUBLIC_URL}/halloween/leatherfacesw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/leatherfacesw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/regansw.png`,
        // `${process.env.PUBLIC_URL}/halloween/regansw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/regansw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/jacksw.png`,
        // `${process.env.PUBLIC_URL}/halloween/frankensteinsw.png`,
        // `${process.env.PUBLIC_URL}/halloween/ghostfacesw.png`,
        // `${process.env.PUBLIC_URL}/halloween/ghostfacesw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/ghostfacesw3.png`,
        // `${process.env.PUBLIC_URL}/halloween/loupgarousw.png`,
        // `${process.env.PUBLIC_URL}/halloween/operasw.png`,
        // `${process.env.PUBLIC_URL}/halloween/myerssw.png`,
        // `${process.env.PUBLIC_URL}/halloween/myerssw2.png`,
        // `${process.env.PUBLIC_URL}/halloween/myerssw.3png`,

        // Photos Noel
        `${process.env.PUBLIC_URL}/noel/groupenoel.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel2.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel3.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel4.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel5.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel6.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel7.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel8.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel9.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel10.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel11.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoel12.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoelmixte.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoelmixte2.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoelmixte3.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoelmixte4.png`,
        `${process.env.PUBLIC_URL}/noel/groupenoelmixte5.png`,
        `${process.env.PUBLIC_URL}/noel/solo.png`,
        `${process.env.PUBLIC_URL}/noel/solo2.png`,
        `${process.env.PUBLIC_URL}/noel/solo3.png`,
        `${process.env.PUBLIC_URL}/noel/solo4.png`,
        `${process.env.PUBLIC_URL}/noel/solo5.png`,
        `${process.env.PUBLIC_URL}/noel/solo6.png`,
        `${process.env.PUBLIC_URL}/noel/solo7.png`,
        `${process.env.PUBLIC_URL}/noel/solo8.png`,
        `${process.env.PUBLIC_URL}/noel/solo9.png`,
        `${process.env.PUBLIC_URL}/noel/solo10.png`,
        `${process.env.PUBLIC_URL}/noel/solo11.png`,
        `${process.env.PUBLIC_URL}/noel/jnsw.png`,
        `${process.env.PUBLIC_URL}/noel/jnsw2.png`,
        `${process.env.PUBLIC_URL}/noel/jnsw3.png`,
        `${process.env.PUBLIC_URL}/noel/cookienoel.png`,
        `${process.env.PUBLIC_URL}/noel/cookienoel2.png`,
        `${process.env.PUBLIC_URL}/noel/cookienoel2.png`,
        `${process.env.PUBLIC_URL}/noel/bruneblonde.png`,
        `${process.env.PUBLIC_URL}/noel/blondechaussetttes.png`,
        `${process.env.PUBLIC_URL}/noel/bruneblondechaussettes.png`,
        `${process.env.PUBLIC_URL}/noel/brunechaussettes.png`,
    ];


    // useEffect(() => {
    //     const randomImage = heroImages[Math.floor(Math.random() * heroImages.length)];
    //     setHeroBackground(randomImage);

    //     const handleResize = () => setIsMobile(window.innerWidth <= 576);
    //     window.addEventListener("resize", handleResize);

    //     // Simule un chargement de 3 secondes
    //     const timer = setTimeout(() => {
    //         setLoading(false);
    //     }, 3000);

    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //         clearTimeout(timer);
    //     };
    // }, []);

    useEffect(() => {
        // Commence par définir l'image de fond initiale
        setHeroBackground(heroImages[0]);

        // Met en place l'intervalle pour la rotation des images
        const intervalId = setInterval(() => {
            setHeroBackground((prevImage) => {
                const currentIndex = heroImages.indexOf(prevImage);
                const nextIndex = (currentIndex + 1) % heroImages.length;
                return heroImages[nextIndex];
            });
        }, 10000); // Rotation toutes les 5 secondes

        // Gestion de la taille de l'écran pour le mode mobile
        const handleResize = () => setIsMobile(window.innerWidth <= 576);
        window.addEventListener("resize", handleResize);

        // Simule un chargement de 3 secondes
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        // Nettoyage des effets au démontage du composant
        return () => {
            clearInterval(intervalId);
            window.removeEventListener("resize", handleResize);
            clearTimeout(timer);
        };
    }, []);

    const handleStationChange = (station) => {
        setSelectedStation(station);
        setPlayingStation(station.name); // Définit la station active pour le halo
    };

    // const handleStop = () => {
    //     setPlayingStation(null); // Arrête le halo lorsque la lecture est stoppée
    // };

    // Styles pour la section hero avec un fond dynamique
    const heroStyles = {
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)), url(${heroBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        textAlign: 'center',
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    };

    // Styles globaux pour l'application, incluant l'image de fond
    const appStyles = {
        // backgroundColor: '#4a2c2c',
        minHeight: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // gap: '20px',
    };

    const mainSectionStyles = {
        width: '100%',
        // backgroundColor: '#4a2c2c', // Choisis la couleur de fond que tu souhaites
        backgroundColor: '#C45648', // Choisis la couleur de fond que tu souhaites
        // backgroundImage: `url(${process.env.PUBLIC_URL}/background/bgbleu3.png)`,
        // backgroundSize: '100% 100%',          // Assure que l'image prend toute la taille de la section
        // backgroundPosition: 'center',      // Centre l'image
        // backgroundRepeat: 'no-repeat',     // Évite que l'image se répète
        // minHeight: '100vh',                // Assure que l'image couvre toute la hauteur de la vue
        padding: '40px 0',           // Ajoute du padding pour un espacement interne
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    };

    return (
        <div style={appStyles} className="App">
            {loading ? (
                <Preloader />
            ) : (
                <>
                    {/* Section Hero */}
                    <section className="hero" style={heroStyles}>
                        <h1>Bienvenue sur Starz Webradio</h1>
                        <p>Écoutez vos stations préférées en direct</p>
                        <a href="#radios">Découvrir les stations</a>
                    </section>
                    {/* Section principale avec carousel ou grille de logos */}
                    <section style={mainSectionStyles} id="radios">
                        <h2 style={{ textAlign: 'center' }}>Choisissez Votre Station Préférée</h2>
                        <div className="carousel-container"> {/* Nouvelle div englobante */}
                            <div className={isMobile ? "radio-carousel" : "radio-grid"}>
                                {radios.map((radio, index) => (
                                    <div
                                        key={index}
                                        className={`radio-item ${playingStation === radio.name ? 'active' : ''}`}
                                        onClick={() => handleStationChange(radio)}
                                    >
                                        <img
                                            src={radio.logo}
                                            alt={`${radio.name} logo`}
                                            style={{
                                                width: "150px",
                                                height: "150px",
                                                borderRadius: "15px",
                                                objectFit: "cover",
                                                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)", // Ombre noire douce
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {/* Lecteur WebRadio */}
                        <WebRadioPlayer
                            selectedStation={selectedStation}
                            setSelectedStation={setSelectedStation}
                            setPlayingStation={setPlayingStation} // Passage correct de la prop
                        />
                    </section>
                    <footer className="footer">
                        <div className="footer-content">
                            <div className="footer-text">
                                <p>&copy; 2024 Starz Webradio. Tous droits réservés.</p>
                                <SocialLinks />
                            </div>
                            <img src={`${process.env.PUBLIC_URL}/cookie/cookienoel.png`} alt="Cookie" className="footer-cookie" />
                        </div>
                    </footer>
                </>
            )}
        </div>
    );
}

function SocialLinks() {
    return (
        <div className="social-links">
            <a href="https://www.facebook.com/fanfan.starzwebradio" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-facebook"></i>
            </a>
            <a href="https://www.instagram.com/starzwebradioofficiel/" target="_blank" rel="noopener noreferrer">
                <i className="bi bi-instagram"></i>
            </a>
        </div>
    );
}

export default App;