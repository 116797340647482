import React from 'react';
import './Preloader.css'; // Assurez-vous d'importer le fichier CSS

const Preloader = () => {
    return (
        <div className="preloader">
            <img src={`${process.env.PUBLIC_URL}/cookie/cookienoel.png`} alt="Preloader" className="cookie-image" />
            <div className="loading-bar">
                <div className="loading"></div>
            </div>
        </div>
    );
};

export default Preloader;